import * as React from "react";
import { Container, Button, Row, Col }  from 'react-bootstrap';

export default function Thankyou() {
  return (
    <div>
      <Container className="text-center homepage padslgt">
      <h1>
        Thankyou for contacting us
      </h1> 
      <p>We'll be in Touch: Our team of digital marketing experts will review your message and get back to you as soon as possible. We understand the importance of timely communication, and we're dedicated to providing you with the support you need.</p>
      </Container>
    </div>
  )
}
import * as React from "react";
import { Link } from 'react-router-dom';
import $ from "jquery";
import axios from 'axios';
import _ from 'lodash'; 
import { Container, Row, Col,Form }  from 'react-bootstrap';
// run this code live and check data is coming or not.
const config = {
    cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
    formUrl: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfe5-uEbawhzT_SeszEK6luOjaGpV4ufRoXL-FS81ejRGdqTw/formResponse'
};

const Input = ({ name, doChange, placeholder, type }) => {
    return (
        <label htmlFor={name} >
            <Form.Control type={type} id={name} name={name} onChange={doChange} placeholder={placeholder} required />

        </label>
    )
}

class Footer extends React.Component {
    state = {
        inputs: {
            name: { id: 1385136515, value: '' },
            email: { id: 696335055, value: '' },
            phone: { id: 1278235114, value: null }, 
            message: { id: 1642471286, value: '' }
        },
    }

    doSubmit = async (e) => {
        e.preventDefault();

        const { inputs } = this.state;
        const formData = new FormData();

        _.map(inputs, (item) => {
            formData.append(`entry.${item.id}`, item.value)
        });

        await axios({
            url: `${config.formUrl}`,
            method: 'post',
            data: formData,
            responseType: 'json'
        })
            .then(function (response) {
                console.log('response', response);

            })
            .catch(function (error) {
                //console.log('err', error);
               // window.location.replace("/thank-you.html") 
            })
            
            (
                message => console.log(message),
                window.setTimeout(function(){
               window.location.replace("/thank-you.html")
                }, 1000)
                ); 
    }

    handleChange = (e) => {
        const { value, name } = e.target;
        const { inputs } = this.state;

        inputs[name].value = value;

        this.setState({
            inputs
        });
    }
    render() { 
      return (

          <div>
      <Container id="footer" fluid className="bgficolor">
        <Container>
          <div className="footer-top">
            <div className="row">
              <div className=" col-lg-3 col-6 about-footer">
                <h3 className="tc-yellow">Drop us a line !</h3>
                <p className="tc-yellow psmall">
                  Make Your Mark in the Ocean of the Digital World with the Best
                  Digital Marketing Agency in India
                </p>

                <div className="footer-logo d-none d-sm-block">
                  <img src={`${process.env.PUBLIC_URL}/img/Contact-Us-formlogo.png`} width="100%" height="100%" />
                </div>
              </div>
              <div className="footer-logo d-block d-sm-none col-6">
                  <img src={`${process.env.PUBLIC_URL}/img/Contact-Us-formlogo.png`} width="100%"  />
                </div>
              <div className="col-lg-3 page-more-info ">
                
                    <a style={{color:"#fff"}} href="tel:+91 +91 8104657758">
                      <p className="psmall">
                        <img src={`${process.env.PUBLIC_URL}/img/call.png`} width="20" />
                        &nbsp;&nbsp; +91 +91 8104657758
                      </p>
                    </a>
                  
                    <p className="psmall">
                      <img src={`${process.env.PUBLIC_URL}/img/mail.png`} width="20" />
                      &nbsp;&nbsp;<a style={{color:"#fff"}}  href="mailto:info@theadmiralmedia.com">info@theadmiralmedia.com</a>
                    </p>
                 
              </div>

              <div className="col-lg-6 page-more-info">
                <form name="contact-form" onSubmit={this.doSubmit} >
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      id="name"
                      name="name"
                      placeholder="eg : Jhon doe" 
                      onChange={this.handleChange} 
                      type="text"
                      required="required"
                      className="form-control" 
                    />
                  </div>
                  <Row>
                    <Col lg="6">
                      <div className="form-group ">
                        <label>E-mail</label>
                        <input
                          id="email"
                          name="email"
                          placeholder="johndoe@abc.com"
                          type="text"
                          required="required"
                          className="form-control"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          onChange={this.handleChange} 
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="form-group ">
                        <label>Phone</label>
                        <input
                          id="phone"
                          name="phone"
                          placeholder="eg : 9565788764"
                          type="text"
                          required="required"
                          maxlength="10" pattern="[0-9]{10}" 
                          className="form-control"
                          onChange={this.handleChange} 
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Please enter you message"
                      cols="40"
                      rows="5"
                      required="required"
                      className="form-control"
                      onChange={this.handleChange} 
                    />
                  </div>

                  <div className="form-group">
                    <button
                      name="submit"
                      type="submit"
                      className="btn btn-primary"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </Container>
      <footer>
        <Container>
          <div className="footer-top">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-6 about-footer">
                <p className="tc-yellow">
                  Ahoy, it sounds like you have some
                  <br /> fresh ideas on the horizon!
                </p>
                <h3>Lets talk !</h3>
                <ul className="d-none d-sm-block">
                  <li>
                    <p>Contact Us</p>
                  </li>
                  <li> 
                    <p>
                      <img src={`${process.env.PUBLIC_URL}/img/mail.png`} width="20" />
                      &nbsp;&nbsp;<a href="mailto:info@theadmiralmedia.com">info@theadmiralmedia.com</a>
                    </p>
                  </li>
                  {/* <li>
                    <a href="tel:(010) 1234 4321">
                      <p>
                        <img src={`${process.env.PUBLIC_URL/img/call.png"} width="20" />
                        &nbsp;&nbsp; (010) 1234 4321
                      </p>
                    </a>
                  </li> */}
                  <li>
                    <p>
                      <img src={`${process.env.PUBLIC_URL}/img/location.png`} width="20" />
                      &nbsp;&nbsp; 908, 9th floor, Sunshine Tower, Tulsi Pipe Rd, Dadar West, Mumbai, Maharashtra 400013
                    </p>
                  </li>
                </ul>
              </div>
              {/* mobile */}
              <div className="col-md-6 col-lg-4 col-6 about-footer d-block d-sm-none pdlr0">
                 
                <ul>
                  <li>
                    <p>Contact Us</p>
                  </li>
                  <li> 
                    <p>
                      <img src={`${process.env.PUBLIC_URL}/img/mail.png`} width="20" />
                      &nbsp;&nbsp;info@theadmiralmedia.com
                    </p>
                  </li>
                  {/* <li>
                    <a href="tel:(010) 1234 4321">
                      <p>
                        <img src={`${process.env.PUBLIC_URL/img/call.png"} width="20" />
                        &nbsp;&nbsp; (010) 1234 4321
                      </p>
                    </a>
                  </li> */}
                  <li>
                    <p>
                      <img src={`${process.env.PUBLIC_URL}/img/location.png`} width="20" />
                      &nbsp;&nbsp;908, 9th floor, Sunshine Tower, Tulsi Pipe Rd, Dadar West, Mumbai, Maharashtra 400013 </p>
                  </li>
                </ul>
              </div>
              
              <div className="col-md-6 col-lg-4 col-7 page-more-info">
                <ul>
                  <li>
                    <a href="">Home</a>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/luxury-media-buying-agency.html`}
                className="" >Media Planning and Buying</Link>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`}
                className="">Search Engine Optimization (SEO)</Link>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/conversion-rate-optimization-services.html`}
                className="">Conversion Rate Optimization</Link>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/orm-negative-ranking-supression-services.html`}
                className="">Online Reputation Management</Link>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/website-development-maintenance-services.html`}
                className="">Tech/Website Maintenance</Link>
                  </li>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/professional-video-production-services-agency.html`}
                className="">Video Editing & Production</Link>
                  </li>
                </ul>
              </div>
              {/* mobile */}
              <div className="col-5 open-hours d-block d-sm-none">
                <div className="footer-logo footerlogopad120">
                  <img src={`${process.env.PUBLIC_URL}/img/footer-logo.png`} width="100%" />
                </div>
              </div>
              <div className="col-md-6 col-lg-2 page-more-info">
                <ul>
                  <li>
                  <Link
                to={`${process.env.PUBLIC_URL}/about-us.html`}
                className="">About Us</Link>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <br />
                </ul>
                <ul className="d-none d-sm-block">
                  <p>
                    <li>Follow</li>
                  </p>
                  <li>
                    <p>
                   <a href="https://www.linkedin.com/company/the-admiral-media" target="blank"> 
                      <img src={`${process.env.PUBLIC_URL}/img/linkedin.svg`} width="30%" height="100%" className="img-fluid" /> Linkedin
                   </a>
                   </p>
                  </li>
                  <li></li>
                  <li>
                    <p>
                  <a href="#" target="blank">
                      <img src={`${process.env.PUBLIC_URL}/img/twitter.svg`} width="30%" height="100%" className="img-fluid" /> Twitter 
                    </a>
                    </p>
                  </li>
                  <li></li>
                  <li> 
                    <p>
                  <a href="https://instagram.com/theadmiralmediadigital" target="blank">
                      <img src={`${process.env.PUBLIC_URL}/img/instagram.svg`} width="30%" height="100%" className="img-fluid" /> Instagram 
                    </a>
                    </p>
                  </li>
                </ul>
                <Row className="col-lg-4"></Row>
              </div>
              <div className="col-md-6 col-lg-2 open-hours d-none d-sm-block">
                <div className="footer-logo footerlogopad120">
                  <img src={`${process.env.PUBLIC_URL}/img/footer-logo.png`} width="100%" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </footer>
      <div className="container-fluid bgfooter">
        <div className="col-sm-12 tc-center">
          <p>Copyright © 2023 The Admiral Media. All rights reserved. </p>
        </div>
      </div>
    </div>
  );
}

}
export default Footer;
import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OtherServices from "./other-services.js";

export default function Cro() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>CRO | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/luxury-seo-agency.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">
                  Conversion Rate Optimization (CRO)
                </h1>
                <p className="padssmt padmxsb">
                  One of the most effective ways to stay ahead of the
                  competition is to invest in conversion rate optimization.
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Sub Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb mbpdtb20">
          <Col lg="4">
            <p className="tc-gry pgrey padmxst">
              Convert more Visitors into Customers with us. - Your CRO crew.
            </p>
            <h3 className="tc-yellow">What is CRO ?</h3>
          </Col>
          <Col lg="1"></Col>
          <Col lg="7">
            <p className="padssmt">
              As the world of digital marketing continues to evolve, staying on
              top of the latest trends has become increasingly important for
              businesses. By optimizing your website for conversions, you can
              turn more of your visitors into customers and increase your
              revenue and profitability.
            </p>
            <p>
              At our agency, we specialize in providing comprehensive CRO
              solutions that cover all aspects of the conversion process. From
              optimizing your landing pages to improving your checkout process,
              we work with you to identify areas for improvement and implement
              strategies that will increase your conversion rates.
            </p>
          </Col>
        </Row>

        <Row className="text-center padbmdb">
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12">
            <img
              src={`${process.env.PUBLIC_URL}/img/149b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              60% of marketers claim that their highest-quality lead source is
              inbound (SEO, blog content, etc.).
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/12b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              3.5 billion searches are typically processed daily by Google's
              algorithm.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/60.png`}
              className="padbxsb mbwd"
              width="80%"
            />

            <p>
              Updated and republished past articles can boost organic traffic by
              up to 106%.
            </p>
          </Col>
        </Row>
      </Container>
      {/* third fold */}
      <Container className="padbxst padbxsb">
        <img
          src={`${process.env.PUBLIC_URL}/img/ship.png`}
          className="wdshp"
          height="100%"
        />
        <p className="tc-gry pgrey padmxst">
          We've got you covered with these sub services
        </p>
        <h3 className="tc-yellow">
          With our top-notch services that are as
          <br /> reliable as the tides
        </h3>
      </Container>
      {/* boxes */}
      <Container fluid id="explore" className="padtlgsb">
        <Col lg="11" md="12" className="text-center offset-lg-1">
          <div className="row mainservices">
            <Col lg="3" md="3" sm="3" className="padssmt col-12 offset-lg-0">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/on-page-seo.png`}
                    className="img-fluid"
                  />
                </div>
                <p className="padmxst">On-Page SEO</p>
                <p className="marmxst marmxsb">
                  On-Page SEO involves optimizing your website’s content,
                  structure, and code to increase your visibility on search
                  engines. This includes creating content that is relevant to
                  your target audience, ensuring your website is properly
                  structured, and optimizing your website’s code for search
                  engine optimization.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/off-page-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Off-Page SEO</p>
                <p className="marmxst marmxsb">
                  Off-Page SEO involves building relationships with websites and
                  webmasters that are relevant to your business, including
                  link-building and social media marketing. This helps you build
                  credibility, gain trust, and drive organic traffic to your
                  website.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/technical-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Technical SEO</p>
                <p className="marmxst marmxsb">
                  Technical SEO involves optimizing the technical aspects of
                  your website such as page speed, website security, and
                  accessibility. This helps you improve the performance of your
                  website, which can lead to higher rankings and better user
                  experience.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/ecommerce-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">E-commerce SEO</p>
                <p className="marmxst marmxsb">
                  E-commerce SEO is specifically designed to improve the
                  visibility of your e-commerce site. This includes optimizing
                  product pages, optimizing checkout processes, and optimizing
                  search engine rankings.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
          </div>
        </Col>
      </Container>
      {/* Sixth row */}
      <div className="evrypagetopfold ">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/seo/why-choose-us.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/seo/why-choose-us-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfoldbottom text-center">
                <img
                  className="padbxsb"
                  src={`${process.env.PUBLIC_URL}/img/media-buying/whychooseicon.png`}
                  width="15%"
                  alt="tam logo"
                />
                <h1 className="tc-yellow">Why Choose us ?</h1>
                <p className="padssmt padmxsb">
                  At The Admiral Media, we understand that conversion rate
                  optimization (CRO) is a critical component of any successful
                  digital marketing strategy.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
       <OtherServices/>
    </div>
  );
}

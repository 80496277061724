import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OtherServices from "./other-services.js";

export default function WebsiteDevelopment() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>Website Development | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/website-development--maintenance-services.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/tech/tech_cover.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/tech/tech_mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">Tech / Website Development<br/> & Maintenance</h1>
                <p className="padssmt padmxsb">
                Hosting services is important to be in a safe place. Our team can help you set up a secure and<br/>
 reliable hosting environment for your website. We guarantee that your website will be up and<br/>
 running all the time, so you don't have to worry about any downtime.
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Sub Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb mbpdtb20">
          <Col lg="4">
            <p className="tc-gry pgrey padmxst">
            Set sail to Develop a successful online presence with us - Your tech crew.
            </p>
            <h3 className="tc-yellow">What is Tech / Website Development & Maintenance?</h3>
          </Col>
          <Col lg="1"></Col>
          <Col lg="7">
            <p className="padssmt">
            Have you ever wondered what website maintenance is? Is it necessary for your business? Well,
 website maintenance is an essential part of running any online business. It helps to keep your
 website running smoothly, making sure your visitors have a good experience when they visit
 your site.
  We offer a comprehensive website service that covers everything from WordPress development
 and maintenance to WooCommerce setup and Shopify website development and maintenance.
 We also offer static development and maintenance, chatbot creation, and hosting services to
 ensure that your website is always running as it should be.
            </p>
          </Col>
        </Row>

        <Row className="text-center padbmdb">
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12">
            <img
              src={`${process.env.PUBLIC_URL}/img/tech/0.05s.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
            Users establish opinions about your website in just 50 milliseconds, or 0.05 seconds, which affects whether they like it or not and if they'll stay on it or not.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/tech/189b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
            The segment's revenue is expected to reach $1.89 billion in US dollars in 2023.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/tech/88-percent.png`}
              className="padbxsb mbwd"
              width="80%"
            />

            <p>
            After a negative experience, 88% of online users are less likely to visit the same website again.
            </p>
          </Col>
        </Row>
      </Container>
      {/* third fold */}
      <Container className="padbxst padbxsb">
        <img
          src={`${process.env.PUBLIC_URL}/img/ship.png`}
          className="wdshp"
          height="100%"
        />
        <p className="tc-gry pgrey padmxst">
          We've got you covered with these sub services
        </p>
        <h3 className="tc-yellow">
          With our top-notch services that are as
          <br /> reliable as the tides
        </h3>
      </Container>
      {/* boxes */}
      <Container fluid id="explore" className="padtlgsb">
        <Col lg="11" md="12" className="text-center offset-lg-1">
          <div className="row mainservices">
            <Col lg="3" md="3" sm="3" className="padssmt col-12 offset-lg-0">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/tech/wordpress.png`}
                    className="img-fluid"
                  />
                </div>
                <p className="padmxst">WordPress Website Development & Maintenance Services</p>
                <p className="marmxst marmxsb">
                include creating new sites,
 optimizing existing ones and making sure they are always up and running. Our team can help
you build a custom web site that not only looks amazing but is also user-friendly and highly
 functional.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/tech/wocommerce.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Woocommerce Setup Services</p>
                <p  className="marmxst marmxsb">
                Woocommerce setup services can help you easily set up your online store and begin selling
 products in no time. With our team of experts, you can rest assured that your store will be set up correctly and optimized for success. 
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/tech/shopify.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Shopify</p>
                <p className="marmxst marmxsb">
                Technical SEO involves optimizing the technical aspects of your website such as page
 speed, website security, and accessibility. This helps you improve the performance of your
website, which can lead to higher rankings and better user experience.
                </p>
                
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/tech/chatbot.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Chatbots</p>
                <p className="marmxst marmxsb">
                E-commerce SEO is specifically designed to improve the visibility of your e-commerce site.
 This includes optimizing product pages, optimizing checkout processes, and optimizing
 search engine rankings.
                </p>
                 
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
          </div>
        </Col>
      </Container> 
      {/* Sixth row */}
      <div className="evrypagetopfold ">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/tech/why_chooseus.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/tech/why_chooseus_mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfoldbottom text-center">
                <img
                  className="padbxsb"
                  src={`${process.env.PUBLIC_URL}/img/media-buying/whychooseicon.png`}
                  width="15%"
                  alt="tam logo"
                />
                <h1 className="tc-yellow">Why Choose us ?</h1>
                <p className="padssmt padmxsb">
                At The Admiral Media, we understand that a successful website requires more than just design and development. That's why we offer comprehensive website development and maintenance services to ensure that your website is fully optimized for success. In addition, our website maintenance services ensure that your website is always up-to-date and running smoothly.  So if you're looking for a partner to help you build and maintain a successful website, look no further than The Admiral Media.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
       <OtherServices/>
    </div>
  );
}

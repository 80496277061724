import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OtherServices from "./other-services.js";

export default function Seo() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>SEO | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/luxury-seo-agency.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">Search Engine Optimization<br/> (SEO)</h1>
                <p className="padssmt padmxsb">
                  The Admiral Media is a leading SEO agency in Mumbai that
                  provides a wide range of SEO<br/> services.
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Sub Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb  mbpdtb20">
          <Col lg="4">
            <p className="tc-gry pgrey padmxst">
              Target the right audience with us - Your SEO crew.
            </p>
            <h3 className="tc-yellow">What is SEO?</h3>
          </Col>
          <Col lg="1"></Col>
          <Col lg="7">
            <p className="padssmt">
              Search engine optimization (SEO) is one of the most important
              strategies for driving organic traffic to your website and
              increasing the visibility of your brand. SEO helps you rank higher
              in search engines which, in turn, increases your visibility,
              competitiveness, and credibility. We offer comprehensive SEO
              solutions that include On-Page SEO, Off-Page SEO, Technical SEO,
              E-commerce SEO, and International SEO.
            </p>
          </Col>
        </Row>

        <Row className="text-center padbmdb">
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12">
            <img
              src={`${process.env.PUBLIC_URL}/img/149b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              60% of marketers claim that their highest-quality lead source is
              inbound (SEO, blog content, etc.).
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/12b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              3.5 billion searches are typically processed daily by Google's
              algorithm.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/60.png`}
              className="padbxsb mbwd"
              width="80%"
            />

            <p>
              Updated and republished past articles can boost organic traffic by
              up to 106%.
            </p>
          </Col>
        </Row>
      </Container>
      {/* third fold */}
      <Container className="padbxst padbxsb">
        <img
          src={`${process.env.PUBLIC_URL}/img/ship.png`}
          className="wdshp"
          height="100%"
        />
        <p className="tc-gry pgrey padmxst">
          We've got you covered with these sub services
        </p>
        <h3 className="tc-yellow">
          With our top-notch services that are as
          <br /> reliable as the tides
        </h3>
      </Container>
      {/* boxes */}
      <Container fluid id="explore" className="padtlgsb">
        <Col lg="11" md="12" className="text-center offset-lg-1">
          <div className="row mainservices">
            <Col lg="3" md="3" sm="3" className="padssmt col-12 offset-lg-0">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/on-page-seo.png`}
                    className="img-fluid"
                  />
                </div>
                <p className="padmxst">On-Page SEO</p>
                <p className="marmxst marmxsb">
                  On-Page SEO involves optimizing your website’s content,
                  structure, and code to increase your visibility on search
                  engines. This includes creating content that is relevant to
                  your target audience, ensuring your website is properly
                  structured, and optimizing your website’s code for search
                  engine optimization.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/off-page-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Off-Page SEO</p>
                <p  className="marmxst marmxsb">
                  Off-Page SEO involves building relationships with websites and
                  webmasters that are relevant to your business, including
                  link-building and social media marketing. This helps you build
                  credibility, gain trust, and drive organic traffic to your
                  website.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/technical-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Technical SEO</p>
                <p className="marmxst marmxsb">
                Technical SEO involves optimizing the technical aspects of your website such as page
 speed, website security, and accessibility. This helps you improve the performance of your
 website, which can lead to higher rankings and better user experience.
                </p>
                
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="3" md="3" sm="3" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/seo/ecommerce-seo.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">E-commerce SEO</p>
                <p className="marmxst marmxsb">
                E-commerce SEO is specifically designed to improve the visibility of your e-commerce site.
 This includes optimizing product pages, optimizing checkout processes, and optimizing
 search engine rankings.
                </p>
                 
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
          </div>
        </Col>
      </Container> 
      {/* Sixth row */}
      <div className="evrypagetopfold ">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/seo/why-choose-us.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/seo/why-choose-us-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfoldbottom text-center">
                <img
                  className="padbxsb"
                  src={`${process.env.PUBLIC_URL}/img/media-buying/whychooseicon.png`}
                  width="15%"
                  alt="tam logo"
                />
                <h1 className="tc-yellow">Why Choose us ?</h1>
                <p className="padssmt padmxsb">
                At The Admiral Media, we understand that conversion rate optimization (CRO) is a critical component of any successful digital marketing strategy.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
      <OtherServices/>
    </div>
  );
}

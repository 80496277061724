import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav,NavDropdown, NavItem,Collapse,Container } from 'react-bootstrap';

import { HashLink } from 'react-router-hash-link'; 

const Navbars = () => {
  const [scrolled, setScrolled] = useState(false);

  // Function to handle scroll event
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
   

<Navbar expand="lg" className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`}>
<Container>
  <Navbar.Brand as={Link} to={`${process.env.PUBLIC_URL}/`} className="d-block d-sm-block d-lg-none"><img src={`${process.env.PUBLIC_URL}/img/tam-logo.png`} alt="" width="90" height="100%" className="img-responsive mblogos" /></Navbar.Brand>

  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="me-auto">
    <Link  to={`${process.env.PUBLIC_URL}/`} className="nav-link">HOME</Link>
            <Link to="/digital-marketing-services-for-luxury-brands.html" className="nav-link"> SERVICES </Link>
            <Navbar.Brand as={Link} to={`${process.env.PUBLIC_URL}/`} className="d-none d-lg-block"><img src={`${process.env.PUBLIC_URL}/img/tam-logo.png`} alt="" width="120" height="100%" className=" img-responsive mblogos" /></Navbar.Brand>
          
            {/* <AnchorLink href="#footer" className="nav-link">CONTACT US</AnchorLink> */}
            <Link  to={`${process.env.PUBLIC_URL}/about-us.html`} className="nav-link">ABOUT US</Link>
            <HashLink to="/#footer" className="nav-link">CONTACT US</HashLink>
            <Link style={{ display: "none" }} to={`${process.env.PUBLIC_URL}/thank-you.html`} className="nav-link">thankyou</Link> 
         
      {/* <Nav.Link href="#home">Home</Nav.Link> */}
      
    </Nav>
  </Navbar.Collapse>
</Container>
</Navbar>
  );
};

export default Navbars;

import * as React from "react"; 
import { Link } from 'react-router-dom';

import { Container, Button, Row, Col } from "react-bootstrap"; 

export default function OtherServices() {
  return (
  <Container className="padslgt padslgb">
        <h1 className="tc-yellow ">Other Services Offered<img src={`${process.env.PUBLIC_URL}/img/Boat-icon.png`} className="ab dnone img-fluid" width="10%" alt="tam logo" />
</h1>

        <Container>
            <Col lg="12" md="12" className="text-center col-12">
              <div className="otherservices">
              <div className="row">
                <Col lg="2" md="2" sm="2" className="padmmdt col-6 ">
                    <Link className="text-white" to= {`${process.env.PUBLIC_URL}/luxury-seo-agency.html`}>
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/SEO.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Search Engine
                      <br /> Optimization (SEO)
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="2" md="2" sm="2" className="padmmdt col-6">
                <Link className="text-white" to= {`${process.env.PUBLIC_URL}/conversion-rate-optimization-services.html`}>
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/CRO.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Conversion Rate
                      <br /> Optimization
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="2" md="2" sm="2" className="padmmdt col-6">
                <Link className="text-white" to= {`${process.env.PUBLIC_URL}/orm-negative-ranking-supression-services.html`}>

                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/ORM.png`}
                        className="img-fluid"
                      />
                    </div>
                    <p> 
                      Online Reputation
                      <br /> Management
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="2" md="2" sm="2" className="padmmdt col-6 ">
                <Link className="text-white" to= {`${process.env.PUBLIC_URL}/website-development-maintenance-services.html`}>

                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/TechWebsite-Maintenance.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Tech/Website
                      <br /> Maintenance
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="2" md="2" sm="2" className="padmmdt col-6 ">
                <Link className="text-white" to= {`${process.env.PUBLIC_URL}/professional-video-production-services-agency.html`}>

                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/Video-Production.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Video Editing &<br /> Production
                    </p>
                  </div>
                  </Link>
                </Col>
              </div>
              </div>
            </Col>
          </Container>
        </Container>
  );
}
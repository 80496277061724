import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Button, Col, Row } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Player } from 'video-react';
import Slider from "react-slick";
export default function Home() {
  return (
    <div>
      <Helmet>
        <title>Digital Marketing Agency in Mumbai | The Admiral Media</title>
        <meta name="description" content="" />
        <link rel="canonical" href="https://theadmiralmedia.com/" />
      </Helmet>
      <Container fluid className="pdlr0">
      
        <video 
        width="100%" 
        height="100vh" 
        autoPlay='true'  
        loop 
        muted 
        className="vf130 d-none d-sm-block">
          <source src={`${process.env.PUBLIC_URL}/video/firstfold-final.mp4`} type="video/mp4" />
        </video>
        <video
          width="100%"
          height="100vh"
          poster={`${process.env.PUBLIC_URL}/img/mobile-cover.png`}
          autoPlay='true'
          muted
          loop
          className="vf130 d-block d-sm-none"
        >
          <source src="video/First-folddesk.mp4" type="video/mp4" />
        </video>
         
      </Container>
      <Container className="overlay">
        <div className="row homepage">
          <div className="col-lg-12">
            <h1 className="text-center padt120">
              Navigating Towards
              <br /> Growth
            </h1>
            <div className="text-center">
              <Button primary href="/digital-marketing-services-for-luxury-brands.html">
                Explore Services
              </Button>
              <p className="padt120s">Let the Voyage Begin</p>
              <AnchorLink href="#scroll-hero" className="btn-dwn">
                <img
                  src={`${process.env.PUBLIC_URL}/img/boat.png`}
                  className="pad50bottom boat"
                  alt="boat"
                  width={60}
                />
              </AnchorLink>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid>
        {/* second fold */}
        <Row id="scroll-hero" className="pad200">
          {/* <Col lg="2" className="">
                <img src={`${process.env.PUBLIC_URL}/img/steering.png`} width="100%" />
            </Col> */}
          <Col md="6" lg="6" sm="7" className="">
            <Row>
              <Col lg="4" md="5" sm="5" className="col-4">
                <img
                  src={`${process.env.PUBLIC_URL}/img/steering.png`}
                  className="mgtop30 box d-none d-sm-block"
                  width="280"
                  alt="steering"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/img/helm-icon.png`}
                  className="mgtop30 box d-block d-sm-none"
                  width="100%"
                  alt="steering"
                />
              </Col>
              <Col lg="8" md="8" sm="8" className="col-12">
                <h3 className="tc-gry mobpdt20">
                  Partner with Mumbai's Leading Marketing Agency
                </h3>
                <h2>
                  Navigate the Digital <br />
                  World with Confidence:
                </h2>
              </Col>
            </Row>
          </Col>

          <Col lg="6" md="5" sm="5">
            <Row>
              <Col lg="11" md="11" sm="11" className="col-12">
                <p className="mobpdt40">
                  The Admiral Media is one of the leading digital marketing
                  agencies in Mumbai. We specialize in media buying and
                  planning, SEO, tech related services, Website development, and
                  more.
                </p>
                <p className="marbxsb">
                  Our dedicated team is committed to providing our clients with
                  the best possible results. We understand that our client's
                  success is our success, and we strive to ensure that all of
                  our clients are satisfied with the results we provide. We take
                  great pride in the quality of our services and the
                  relationships we build with our clients. With The Admiral
                  Media, you can trust that you are getting the highest quality
                  of digital marketing services.
                </p>
                <Button primary href="#">
                  More About Us
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg="1" md="1" sm="1"></Col>
        </Row>
      </Container>
      {/* Third Fold */}
      <Container fluid className="experienced padblgb">
        <Row>
          <img
            src={`${process.env.PUBLIC_URL}/img/cap.png`}
            width="20%"
            height="auto"
            className="mx-auto img-fluid"
            alt="Cap"
          />
          <Col lg="12" md="12" className="text-center padmmdb mobpdb20">
            <h3 className="tc-gry">Started in 2019 By A Crew of </h3>
            <h2>Experienced Digital Marketers</h2>
          </Col>

          <Container>
            <Col lg="12" md="12" className="text-center">
              <div className="row">
                <Col lg="3" md="4" sm="3" className="offset-lg-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/Experience-Excellence.png`}
                    className="img-fluid padssmb mobpdb20 icondwid40"
                  />
                  <h5>Experience Excellence</h5>
                  <p>
                    <br />
                    With our team of experienced professionals, we are renowned
                    for delivering successful campaigns and strategies that
                    deliver results.
                  </p>
                </Col>
                {/* <Col lg="1" md="1" sm="1"></Col> */}
                <Col lg="3" md="4" sm="3" className="offset-lg-1 offset-md-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/Revolutionize-Your-Digital-Presence.png`}
                    className="img-fluid padssmb  mobpdb20 icondwid40"
                  />

                  <h5>
                    Revolutionize Your Digital <br />
                    Presence
                  </h5>
                  <p>
                    We specialize in providing innovative and effective digital
                    marketing solutions to businesses of all sizes and across
                    all industries.
                  </p>
                </Col>
                {/* <Col lg="1" md="1" sm="1"></Col> */}
                <Col lg="3" md="4" sm="3" className="offset-lg-1 offset-md-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/UniqueNeedsTailoredSolutions.png`}
                    className="img-fluid padssmb mobpdb20 icondwid40"
                  />

                  <h5>
                    Unique Needs, Tailored
                    <br /> Solutions:
                  </h5>
                  <p>
                    We develop personalized solutions for each client,
                    leveraging our team's expertise to identify their unique
                    strengths and weaknesses.{" "}
                  </p>
                </Col>
              </div>
            </Col>
          </Container>
        </Row>
      </Container>
      {/* fourth fold */}
      <Container fluid className="experienced padblgb">
        <Row>
          <div className="containerbox">
            <img
              src={`${process.env.PUBLIC_URL}/img/digital-brand.png`}
              className="d-none d-sm-block mx-auto img-fluid"
              alt="image"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/digital-brand-mobile.png`}
              width="100%"
              className="d-block d-sm-none mx-auto img-fluid"
              alt="image"
            />

            <div className="centered">
              <h2>
                “Let us help you create a Strong Digital Brand that washes over
                the online world and connects with your desired audience”
              </h2>
            </div>
          </div>
        </Row>
      </Container>
      {/* Fifth fold */}
      <img
            src={`${process.env.PUBLIC_URL}/img/boat.png`}
            width={60}
            className="divbox img-fluid d-none d-sm-block"
            alt="boat"
          />
      <Container fluid className="experienced padblgb">
        <Row>
          <img
            src={`${process.env.PUBLIC_URL}/img/ship.png`}
            width="100%"
            className="mx-auto img-fluid wdshp d-none d-sm-block"
            alt="Ship"
          />
          <img
            src={`${process.env.PUBLIC_URL}/img/ship.png`}
            width={60}
            className="mx-auto img-fluid wdshp d-block d-sm-none"
            alt="Ship"
          />
          <Col lg="12" md="12" className="text-center ">
            <h3 className="tc-gry mgb0">
              Set Sail into the Future with Our Revolutionary
            </h3>
            <h2>Digital Marketing Services</h2>
          </Col>

          <Container>
            <Col lg="12" md="12" className="text-center">
              <div className="row">
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-0">
                <Link
                to={`${process.env.PUBLIC_URL}/luxury-media-buying-agency.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/Media-planning.png`}
                        className="img-fluid"
                      />
                    </div>
                    <p> 
                      Media Planning &<br /> Buying
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-1">
                <Link
                to={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/SEO.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Search Engine
                      <br /> Optimization (SEO)
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-1">
                <Link
                to={`${process.env.PUBLIC_URL}/conversion-rate-optimization-services.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/CRO.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Conversion Rate
                      <br /> Optimization
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-0">
                <Link
                to={`${process.env.PUBLIC_URL}/orm-negative-ranking-supression-services.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/ORM.png`}
                        className="img-fluid"
                      />
                    </div>
                    <p> 
                      Online Reputation
                      <br /> Management
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-1">
                <Link
                to={`${process.env.PUBLIC_URL}/website-development-maintenance-services.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/TechWebsite-Maintenance.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Tech/Website
                      <br /> Maintenance
                    </p>
                  </div>
                  </Link>
                </Col>
                <Col lg="3" md="3" sm="3" className="padmmdt col-6 offset-lg-1">
                <Link
                to={`${process.env.PUBLIC_URL}/professional-video-production-services-agency.html`} className="text-white">
                  <div className="black">
                    <div className="white">
                      <img
                        src={`${process.env.PUBLIC_URL}/img/Video-Production.png`}
                        className="img-fluid padssmb"
                      />
                    </div>
                    <p>
                      Video Editing &<br /> Production
                    </p>
                  </div>
                  </Link>
                </Col>
              </div>
            </Col>
          </Container>
        </Row>
      </Container>
      {/* our clients */}
      <Container fluid className="experienced padblgb">
        <Row>
          <div className="containerbox">
            <img
              src={`${process.env.PUBLIC_URL}/img/handshake.png`}
              className="mx-auto img-fluid d-none d-sm-block"
              alt="image"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/handshake-mobile.png`}
              className="mx-auto img-fluid d-block d-sm-none"
            />
            <div className="leftc">
              <h2>
                “Our clients are not just data points or metrics, they are our
                valued collaborators.”
              </h2>
            </div>
          </div>
        </Row>
      </Container>
      {/* our partners */}
      <Container fluid className="padblgb">
        <Col lg="10" md="10" className="offset-lg-2 text-left">
          <h3 className="tc-gry mgb0">
            Take a Look at the Brands we helped set sail
          </h3>
          <h2>Our Partners</h2>
        </Col>
        <Col lg="10" md="10" className="offset-lg-2 leftbr">
          <Row className="imgtc marmmdt text-center">
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/totalsports.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Crepdog-Crew.avif`}
                className="imgwdth cbnimg "
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Bluetribe.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col> 
            <div className="col d-none d-sm-block"></div> 
{/*             
          </Row>
          
          <Row className="imgtc marmmdt text-center"> */}
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Storm_logo.jpg`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/optically.jpg`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>

            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/temple_logo.jpg`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <div className="col d-none d-sm-block"></div> 

          {/* </Row>
          <Row className="imgtc marmmdt text-center"> */}
            <Col lg="2" sm="3" className=" offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Finwizz.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className=" offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/ADC.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/smca-logo.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            
            <div className="col d-none d-sm-block"></div> 

          {/* </Row>
          <Row className="imgtc marmmdt text-center"> */}
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Sailsmith_Final_logo_White_n_black_v01.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Kann-Design.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Truved-Final-Logo.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <div className="col d-none d-sm-block"></div> 

            
          {/* </Row>
          <Row className="imgtc marmmdt text-center"> */}
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/happy-cows-milk-logo.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/name_string.png`}
                className="imgwdth cbnimg"
                width="100%"
                height="auto"
              />
            </Col>
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/viratstudios.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />

          </Col>
          <div className="col d-none d-sm-block"></div> 

{/*             
          </Row>
          <Row className="imgtc mgt0 marbsmt text-center"> */}
         
          <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/Unboxing.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
            
            <Col lg="2" sm="3" className="offset-lg-1 offset-sm-1 col-6 mgtb80">
              <img
                src={`${process.env.PUBLIC_URL}/img/clients/yashgreen.png`}
                className="imgwdth clnimg"
                width="100%"
                height="auto"
              />
            </Col>
           
              
          </Row>
        </Col>
      </Container>
    </div>
  );
}

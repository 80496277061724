import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Aboutus() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>About US | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/about-us.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/about-us.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/about-us-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">
                About Us
                </h1>
                <p className="padssmt ">
                We are an aware brand unlike the most players in the market who claim to
 be professionals at everything. We know we are not the experts in each &
 every field but with the data backed  esearch & tests, we figure out our way
 to fruitful results.
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb mbpdtb20">
        <Col lg="2">
        <img
              src={`${process.env.PUBLIC_URL}/img/aboutus/light-house.png`}
              className="d-none d-sm-block"
              width="80%"
            />
              <img
              src={`${process.env.PUBLIC_URL}/img/aboutus/light-house.png`}
              className="d-block d-sm-none"
              width="50%"
            />
        </Col>
          <Col lg="4 mbpdtb20">
            <p className="tc-gry pgrey ">
            Navigating the ever-changing tides of digital marketing for your success.            </p>
            <h3 className="tc-yellow">Our Mission</h3>
          </Col> 
          <Col lg="6">
            <p className="">
            We are the admirers of the deep digital ocean and our crew is here to help
brands sail from one destination to other with our specialized compass
(SEO, Media & Tech) that assures smooth transition towards a beautiful
growth
. We are a brand which represents premium quality and are into driving quality
 whales & sharks on our brands ship!  The Admiral Media (TAM) is a Digital Marketing Agency that specializes in
 SEO, Media Buying/Planning & Tech related services as a whole. We aim
 to serve premium & interesting brands to drive quality website traffic at
 every stage of their marketing journey (Marketing Funnels) that take
 actions.
            </p>
          </Col>
        </Row>
 
      </Container>
      <Container fluid className="experienced padblgb">
        <Row>
          <img
            src={`${process.env.PUBLIC_URL}/img/cap.png`}
            width="20%"
            height="auto"
            className="mx-auto img-fluid d-none d-sm-block"
            alt="Cap"
          />
           <img
            src={`${process.env.PUBLIC_URL}/img/cap.png`}
            width="50%"
            height="auto"
            className="mx-auto img-fluid d-block d-sm-none"
            alt="Cap"
          />
          <Col lg="12" md="12" className="text-center padmmdb mobpdb20">
            <h3 className="tc-gry"> Sailing towards Success: The Admiral Media's<br/> Top Three Digital Marketing Strengths </h3>
            <h2>What we are best at</h2>
          </Col>

          <Container>
            <Col lg="12" md="12" className="text-center">
              <div className="row">
                <Col lg="3" md="4" sm="3" className="offset-lg-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/Experience-Excellence.png`}
                    className="img-fluid padssmb mobpdb20 icondwid40"
                  />
                  <h5>Results-Driven Digital Marketing Campaigns:</h5>
                  <p>
                    <br />
                    We take a data-driven approach to create custom solutions that meet our clients' unique needs and goals, constantly analyzing and optimizing campaigns to ensure peak performance
                  </p>
                </Col>
                {/* <Col lg="1" md="1" sm="1"></Col> */}
                <Col lg="3" md="4" sm="3" className="offset-lg-1 offset-md-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/Revolutionize-Your-Digital-Presence.png`}
                    className="img-fluid padssmb  mobpdb20 icondwid40"
                  />

                  <h5>
                  Innovative Digital Marketing Solutions for Every Business
                  </h5>
                  <p>
                  We pride ourselves on our ability to provide innovative and effective digital marketing solutions to businesses of all sizes and across all industries.
                  </p>
                </Col>
                {/* <Col lg="1" md="1" sm="1"></Col> */}
                <Col lg="3" md="4" sm="3" className="offset-lg-1 offset-md-0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/UniqueNeedsTailoredSolutions.png`}
                    className="img-fluid padssmb mobpdb20 icondwid40"
                  />

                  <h5>
                  Personalized Solutions for Clients
                  </h5>
                  <p>
                  Our team utilizes their expertise to evaluate the distinct strengths and weaknesses of each client, enabling us to create customized solutions that meet their specific needs.
                  </p>
                </Col>
              </div>
            </Col>
          </Container>
        </Row>
      </Container>
      {/* third fold */}
      
     
      {/* Sixth row */}
      <div className="evrypagetopfold mbmgtb20">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/we-aim.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/we-aim-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />
              <div className="top-leftabouts">
                <h1 className="tc-yellow"> <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/aim.png`}
                width="100"
                className="  mx-auto img-fluid"
                alt=""
              /> What we aim?</h1>
                </div>
              <div className="aboutustopfoldbottom  text-center">
               
            
                
                <img
                src={`${process.env.PUBLIC_URL}/img/aboutus/digitalmessage.png`}
                width="80%"
                className=" mx-auto img-fluid"
                alt="Unleashing the digital potential of your brand - with the power of the ocean."
              />
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
      
    </div>
  );
}

import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function Services() {
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>Digital Marketing Services | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/digital-marketing-services-for-luxury-brands.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">Our Services</h1>
                <p className="padssmt padmxsb">
                  Embark on a journey towards digital marketing success with
                  Admiral Media - where time and
                  <br /> money are maximized for unparalleled value!"
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Our Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
        <Container className="padbxst padbxsb">
          <img
            src={`${process.env.PUBLIC_URL}/img/ship.png`}
           className="wdshp"
            height="100%"
          />
          <p className="tc-gry pgrey padmxst">Set Sail with Us</p>
          <h3 className="tc-yellow">
            Navigate Your Needs with Our <br />
            Top-Notch Services
          </h3>

          <Row id="explore" className="padbmdt mobpdb40">
            <Col lg="5" md="6" sm="4">
              <h3 className="tc-yellow">Media Planning & Buying:</h3>
              <p className="padmxsb padmxst">
                We help our clients create and manage their online advertising
                campaigns, ensuring that their brand reaches the right audience.
                The Admiral Media offers a complete range of media buying
                services, encompassing various platforms like Instagram,
                Facebook, Google Ads, Amazon Ads, Snapchat Ads, Affiliates, and
                more.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/luxury-media-buying-agency.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>
            </Col>
            <Col lg="2" md="1" sm="1"></Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
              <img
                src={`${process.env.PUBLIC_URL}/img/Media-Planning-and-Buying.png`}
                width="100%"
                height="100%"
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row className="padbmdt mobpdb40">
            {/* desktop image */}
            <Col lg="5" md="5" sm="5">
              <img
                src={`${process.env.PUBLIC_URL}/img/SEO-image.png`}
                width="100%"
                height="100%"
                className="img-fluid d-none d-sm-block"
              />
            </Col>
            <Col lg="2" md="1" sm="1"></Col>

            <Col lg="5" md="6" sm="6">
              <h3 className="tc-yellow">Search Engine Optimization (SEO)</h3>
              <p className="padmxsb padmxst">
                We help our clients gain visibility in search engines by
                optimizing their websites and content for improved search engine
                rankings. Our SEO services cover a wide range of solutions,
                encompassing On-Page SEO, Off-Page SEO, Technical SEO,
                E-commerce SEO, and International SEO, to provide you with a
                holistic approach to optimizing your website.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>

              
            </Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
            <img
                src={`${process.env.PUBLIC_URL}/img/cro-image.png`}
                width="100%"
                height="100%"
                className="img-fluid d-block d-sm-none "
              />
            </Col>
            
          </Row>
          <Row className="padbmdt mobpdb40">
            <Col lg="5" md="5" sm="4">
              <h3 className="tc-yellow">Conversion Rate Optimization (CRO)</h3>
              <p className="padmxsb padmxst">
                Our conversion rate optimization services help business optimize
                their website and landing pages to increase conversions and
                revenue. Our collaboration with you involves pin pointing areas
                for enhancement, from refining your landing pages to
                streamlining your checkout process and enhancing techniques that
                will boost your conversion rates .
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/conversion-rate-optimization-services.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>
            </Col>
            <Col lg="2" md="1" sm="1"></Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
              <img
                src={`${process.env.PUBLIC_URL}/img/CRO-image.png`}
                width="100%"
                height="100%"
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row className="padbmdt mobpdb40">
            {/* desktop */}
            <Col lg="5" md="5" sm="5">
              <img
                src={`${process.env.PUBLIC_URL}/img/orm-image.png`}
                width="100%"
                height="100%"
                className="img-fluid d-none d-sm-block"
              />
            </Col>
            <Col lg="2" md="1" sm="1"></Col>

            <Col lg="5" md="6" sm="6">
              <h3 className="tc-yellow">Online Reputation Management (ORM)</h3>
              <p className="padmxsb padmxst">
                With our negative link suppression service, we help our clients
                in controlling the narrative of their brand and manage ranking
                suppression. The technique of negative ranking suppression aims
                to safeguard and enhance a brand's online image by pushing down
                unfavorable content and elevating positive content to the top of
                search engine results.{" "}
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/orm-negative-ranking-supression-services.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>
            </Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
              {/* mobile */}
              <img
                src={`${process.env.PUBLIC_URL}/img/orm-image.png`}
                width="100%"
                height="100%"
                className="img-fluid d-block d-sm-none"
              />
            </Col>
          </Row>
          <Row className="padbmdt mobpdb40">
            <Col lg="5" md="5" sm="4">
              <h3 className="tc-yellow">Tech/Website Maintenance:</h3>
              <p className="padmxsb padmxst">
                We offer a full suite of website maintenance services, from
                making sure your website is updated regularly to making sure it
                is secure. Our website service is all-encompassing, spanning
                from WordPress development and upkeep, to setting up WooCommerce
                and Shopify websites, and providing ongoing maintenance for all.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/website-development-maintenance-services.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>
            </Col>
            <Col lg="2" md="1" sm="1"></Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
              <img
                src={`${process.env.PUBLIC_URL}/img/Website-Development.png`}
                width="100%"
                height="100%"
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row className="padbmdt padbmdb mobpdb40">
            <Col lg="5" md="5" sm="5">
              {/* desktop */}
              <img
                src={`${process.env.PUBLIC_URL}/img/Video-Editing.png`}
                width="100%"
                height="100%"
                className="img-fluid d-none d-sm-block"
              />
            </Col>
            <Col lg="2" md="1" sm="1"></Col>

            <Col lg="5" md="6" sm="6">
              <h3 className="tc-yellow">Video Editing & Production </h3>
              <p className="padmxsb padmxst">
                Our video editing and production services help businesses create
                high-quality video content that engages their audience. We offer
                an extensive array of services pertaining to digital marketing
                and video production.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/professional-video-production-services-agency.html`}
                className="btn btn-primary"
              >
                Read More
              </Link>
            </Col>
            <Col lg="5" md="5" sm="5" className="mobpdt20">
              {/* mobile */}
              <img
                src={`${process.env.PUBLIC_URL}/img/Video-Editing.png`}
                width="100%"
                height="100%"
                className="img-fluid d-block d-sm-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OtherServices from "./other-services.js";

export default function Orm() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div className="services container-fluid">
      <Helmet>
        <title>ORM | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/orm-negative-ranking-supression-services.html"
        />
      </Helmet>
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/services-cover-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">
                  ORM - Negative Ranking suppression
                </h1>
                <p className="padssmt padmxsb">
                  At The Admiral Media, we understand the importance of having a
                  positive online presence and are committed to helping our
                  clients achieve this.
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Sub Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb mbpdtb20">
          <Col lg="4">
            <p className="tc-gry pgrey padmxst">
              Build a positive online reputation with our - ORM crew{" "}
            </p>
            <h3 className="tc-yellow">What is ORM ?</h3>
          </Col>
          <Col lg="1"></Col>
          <Col lg="7">
            <p className="">
              People trust search engines more than ever before. Therefore, if
              negative content about your business or person shows up when a
              person searches for you, it can have a serious impact on your
              reputation, sales, and overall success. Negative ranking
              suppression is used to protect and improve a brand's online
              reputation by suppressing negative content and allowing positive
              content to be visible on top of the search engine results. It is
              especially important for businesses to use this service, as
              negative content can have a serious impact on their bottom line.
            </p>
          </Col>
        </Row>

        <Row className="text-center padbmdb">
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12">
            <img
              src={`${process.env.PUBLIC_URL}/img/orm/54-percent.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              According to 54% of customers, businesses must fundamentally
              change how they interact with their customers.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/orm/40-percent.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
              About 40 satisfied customers are required to undo the effects of
              one bad review.
            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/orm/85-percent.png`}
              className="padbxsb mbwd"
              width="80%"
            />

            <p>
              Online reviews are trusted more than personal recommendations by
              85% of consumers.
            </p>
          </Col>
        </Row>
      </Container>
      {/* third fold */}
      <Container className="padbxst padbxsb">
        <img
          src={`${process.env.PUBLIC_URL}/img/ship.png`}
          className="wdshp"
          height="100%"
        />
        <p className="tc-gry pgrey padmxst">
          We've got you covered with these sub services
        </p>
        <h3 className="tc-yellow">
          With our top-notch services that are as
          <br /> reliable as the tides
        </h3>
      </Container>
      {/* boxes */} 
      <Container className="marbmdb marbsmt">
      <Col lg="12" md="12" sm="12">
          <Row>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/orm/analysis.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">Analyzing existing content: </h5>
                  <p>
                  We will analyze existing content and identify any negative ranking
 content that needs to be suppressed.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/orm/monitoring.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">Monitoring content:</h5>
                  <p>
                  We will monitor the content regularly to ensure that it does not reappear in
 the future.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/orm/supressing.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">Suppressing negative content: </h5>
                  <p>
                  We will use our expertise to suppress negative content from
appearing in the search engine results.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/orm/generating.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">Generating positive content:</h5>
                  <p>
                  We will create new content for your brand to ensure that the
 brand’s online reputation is constantly being improved.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        </Container>
      {/* Sixth row */}
      <div className="evrypagetopfold ">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/orm/chooseus.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/orm/choose-us-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfoldbottom text-center">
                <img
                  className="padbxsb"
                  src={`${process.env.PUBLIC_URL}/img/media-buying/whychooseicon.png`}
                  width="15%"
                  alt="tam logo"
                />
                <h1 className="tc-yellow">Why Choose us ?</h1>
                <p className="padssmt padmxsb">
                  We understand that your reputation is one of your greatest
                  assets. That’s why we offer comprehensive Negative Ranking
                  Suppression Services to help you protect it. With our
                  services, you can rest assured that your business is free from
                  any negative content that could be damaging your success. So,
                  don’t wait any longer – contact us today and let us help you
                  get the most out of your online reputation!
                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
      <OtherServices/>
    </div>
  );
}

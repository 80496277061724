import React from 'react';
import { Link,Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Button, Col, Row } from 'react-bootstrap';  

const NotFound = () => ( 
    <div> <Helmet>
  
      <title>404</title>
      <meta name="description" content="404" />
      <meta name="prerender-status-code" content="404" />    
      <meta name="robots" content="noindex" />
      <link rel="canonical" href="https://theadmiralmedia.com/404.html" /> 
  
    </Helmet>
    <div> 
      <div className="container text-center padmmdt padmmdb">
        <h1>404</h1>
        <p className='padmsmb'>
        Go to our Homepage: Visit our homepage to explore our wide range of digital marketing services <br/>and learn more about how we can help your business grow.
        </p>
       
        <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-primary "> Back to home</Link>

      </div>
    </div>
    </div> 
  );
  export default NotFound;
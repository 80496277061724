import ReactDOM from 'react-dom';
import React from 'react';
import Navbars  from './header';
import Footer from './footer';
import Home from './home';
import Services from './services';
import MediaPlanning from './media-planning';
import Seo from './seo';
import Cro from './cro';
import Orm from './orm';
import Thankyou from './thank-you';
import WebsiteDevelopment from './websitedevelopment';
import VideoProduction from './video-production';
import Aboutus from './about-us';
import NotFound from './NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css';
 

import { withRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
const App = (props) => { 
  return (
    <div className="App">
      <Navbars/>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL}/digital-marketing-services-for-luxury-brands.html`} component={Services} />   
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-media-buying-agency.html`} component={MediaPlanning} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/luxury-seo-agency.html`} component={Seo} />
        <Route exact path={`${process.env.PUBLIC_URL}/conversion-rate-optimization-services.html`} component={Cro} />
        <Route exact path={`${process.env.PUBLIC_URL}/orm-negative-ranking-supression-services.html`} component={Orm} />
        <Route exact path={`${process.env.PUBLIC_URL}/website-development-maintenance-services.html`} component={WebsiteDevelopment} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/professional-video-production-services-agency.html`} component={VideoProduction} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/about-us.html`} component={Aboutus} /> 
        <Route exact path={`${process.env.PUBLIC_URL}/thank-you.html`} component={Thankyou} /> 
        <Route path={`${process.env.PUBLIC_URL}/404.html`} component={NotFound} /> 
        <Redirect from='*' to={`${process.env.PUBLIC_URL}/404.html`} /> 
        <Footer />
      </Switch>
        
      {/* for side hide us this method => props.location.pathname!=='/casestudy/evok' ? <Footer/>:null,  */}
      {
      props.location.pathname !== '/thank-you.html' &&
        props.location.pathname !== '/404.html' && 
        <Footer />}

    </div>
  );

};
export default withRouter(App);


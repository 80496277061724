import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, Route } from "react-router-dom";
import { Container, Button, Row, Col } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import OtherServices from "./other-services.js";

export default function VideoProduction() {
  window.scrollTo(0, 0);
  const [focus, setFocus] = React.useState(false);
  return (
    <div>
      <Helmet>
        <title>Video Production | The Admiral Media</title>
        <meta name="description" content="" />
        <link
          rel="canonical"
          href="https://theadmiralmedia.com/professional-video-production-services-agency.html"
        />
      </Helmet>

    <Container fluid className="services ">
      <div className="evrypagetopfold">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/video/video-production.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/video/video-production-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfold">
                <h1 className="tc-yellow">Video Editing & Production</h1>
                <p className="padssmt padmxsb">
                At The Admiral Media, we know that high-quality video content is essential for building a<br/> strong online presence. . Let us help you tell your brand's story through the power of video
                </p>
                <AnchorLink href="#explore" className="btn btn-primary">
                  Explore Sub Services
                </AnchorLink>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* second fold */}
      <Container>
        <Row className="padslgt padslgb mbpdtb20">
          <Col lg="4">
            <p className="tc-gry pgrey padmxst">
            Set sail To Create impactful video content with us - video editing and production crew
            </p>
            <h3 className="tc-yellow">What is Video Editing & Production</h3>
          </Col>
          <Col lg="1"></Col>
          <Col lg="7">
            <p className="padssmt">
            Looking to elevate your brand's online presence? Top-notch video production services are available from our digital marketing company, which will make you stand out from the competition. Our company provides a wide range of services relating to digital marketing and video production. Videos are more visually appealing than text or still images alone and can tell a story or deliver a message in a way that is memorable and intriguing. As a result of the audience's increased engagement, firms may be able to boost customer loyalty and brand recognition. Additionally, especially when posted on social media platforms, videos have the ability to reach a wider audience and gain new followers.
            </p>
          </Col>
        </Row>

        <Row className="text-center padbmdb">
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12">
            <img
              src={`${process.env.PUBLIC_URL}/img/video/232m.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
            With over 232 million active internet users, we have a big hunger for watching videos.            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/video/4.5b.png`}
              className="padbxsb mbwd"
              width="80%"
            />
            <p>
            The video editing and production market size is projected to reach USD 3.24 bn by 2030            </p>
          </Col>
          <Col lg="1" sm="1" className="col-1"></Col>
          <Col lg="3" sm="3" className="col-12 mobpdt20">
            <img
              src={`${process.env.PUBLIC_URL}/img/video/91percent.png`}
              className="padbxsb mbwd"
              width="80%"
            />

            <p>
            According to Wyzowl, more than 91% of companies use video as a marketing strategy.            </p>
          </Col>
        </Row>
      </Container>
      {/* third fold */}
      <Container className="padbxst padbxsb">
        <img
          src={`${process.env.PUBLIC_URL}/img/ship.png`}
          className="wdshp"
          height="100%"
        />
        <p className="tc-gry pgrey padmxst">
        We've got you covered with these sub services 
        </p>
        <h3 className="tc-yellow">
        With our top-notch services that are as<br/> reliable as the tides
        </h3>
      </Container>
      {/* boxes */}
      <Container fluid id="explore" className="padtlgsb mbpdtb20">
        <Col lg="11" md="12" className="text-center offset-lg-1">
          <div className="row mainservices">
            <Col lg="4" md="4" sm="4" className="padssmt col-12 offset-lg-0">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/video-editing.png`}
                    className="img-fluid"
                  />
                </div>
                <p className="padmxst">Video Editing</p>
                <p className="marmxst marmxsb">
                This service can include tasks such as adding effects, transitions, and music, as well as removing unwanted footage and adjusting the color and tone of the video. A video editing service can be utilized for a variety of purposes, including commercial advertising, social media content, and personal projects.
                </p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="4" md="4" sm="4" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/video-pro.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Video Production</p>
                <p  className="marmxst marmxsb">
                Video production refers to the entire process of creating a video, from pre-production planning to post-production editing. From concept development and script writing to filming and editing the material, our video production services cover it all. We provide animations for a variety of platforms, including GIFs, logos, Snap lenses, and Instagram AR effects.</p>
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
            <Col lg="4" md="4" sm="4" className="padssmt col-12 ">
              <div className="media-black">
                <div className="media-white">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/motion-graphics.png`}
                    className="img-fluid padssmb"
                  />
                </div>
                <p className="padmxst">Motion Graphics</p>
                <p className="marmxst marmxsb">
                Motion graphics can help brands to create engaging visual content that captures their audience's attention, tell their story, and help grow their online presence. For instance, digital marketing agencies can create animated videos that showcase a brand's products or services, explain a complex concept, or tell a story in an engaging way.
                </p>
                
              </div>
              <p className="margin-100">
                <AnchorLink href="#footer" className="btn btn-primary">
                  Request Service
                </AnchorLink>
              </p>
            </Col>
             
          </div>
        </Col>
      </Container> 
      <Container className="padbxst padbxsb">
       
        <h3 className="tc-yellow">
        Here’s how we Stand Out in the Sea of Life
        </h3>
      </Container>
      <Container className="marbmdb marbsmt">
      <Col lg="12" md="12" sm="12">
          <Row>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/impactful-stories.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">We create impactful stories: </h5>
                  <p>
                  We collaborate with our clients to create concepts that effectively reach their target demographic and are consistent with their brand statement. Our team of talented writers will create a script that effectively communicates your message and holds the attention of your audience.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col lg="6" md="6" sm="6" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="3" md="3" sm="3" className="col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/impeccable.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="9" md="9" sm="9" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">Our diligence is impeccable:</h5>
                  <p>
                  pre-production tasks including casting, scheduling, and other logistics are all handled by us. Your video will be edited by us into a polished, unified piece that effectively tells your narrative and conveys your message.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="padmsmb col-12 mbpdlr0">
              <Row>
                <Col lg="2" md="3" sm="3" className="offset-lg-2  col-4 mbpdlr0">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/video/delivery.png`}
                    width="100%"
                  />
                </Col>
                <Col lg="6" md="6" sm="6" className="col-8 mbpdlr0">
                  <h5 className="tc-yellow">We never compromise on our delivery:</h5>
                  <p>
                  We offer in-depth statistics and analysis on your video's performance, including views, engagement, and conversions, to help you improve your video strategy and meet your deadlines.
                  </p>
                </Col>
              </Row>
            </Col>
             
          </Row>
        </Col>
        </Container>
      {/* Sixth row */}
      <div className="evrypagetopfold ">
        <div className="experienced">
          <Row>
            <div className="containerbox boxs">
              <img
                src={`${process.env.PUBLIC_URL}/img/video/whychooseus.png`}
                className="d-none d-sm-block mx-auto img-fluid"
                alt="image"
              />
              <img
                src={`${process.env.PUBLIC_URL}/img/video/whychooseus-mobile.png`}
                width="100%"
                className="d-block d-sm-none mx-auto img-fluid"
                alt="image"
              />

              <div className="servpagetopfoldbottom text-center">
                <img
                  className="padbxsb"
                  src={`${process.env.PUBLIC_URL}/img/media-buying/whychooseicon.png`}
                  width="15%"
                  alt="tam logo"
                />
                <h1 className="tc-yellow">Why Choose us ?</h1>
                <p className="padssmt padmxsb">
                We can produce captivating and visually appealing videos that will attract your audience and make a lasting impact thanks to our team of talented specialists. To help our clients effectively communicate their brand message and engage their target audience, we provide a wide range of video production services. Our team of skilled video producers, writers, and creatives collaborates closely with our customers to fully grasp their unique needs and objectives and provide outcomes that are superior to their expectations.                </p>
              </div>
            </div>
          </Row>
        </div>
      </div>
      {/* Seventh Row */}
     <OtherServices/>
    </Container>
    </div>
  );
}
